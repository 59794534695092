import React from "react";
import "./App.css";
import '@fontsource/inter';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Sheet from '@mui/joy/Sheet';
import { JobList } from "./components/joblist/JobList";
import { Input, Stack, Typography } from "@mui/joy";
import { StyleToggle } from "./components/styletoggle/StyleToggle";


function App() {
  const [filter, setFilter] = React.useState('' as string);
  const updateFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  }

  return (
    <CssVarsProvider defaultMode="system">
      <CssBaseline />
      <Sheet>
        <Stack mx={5}>
          <Stack flexDirection="row" justifyContent="space-between" marginTop={5}>
            <Typography level="h1">GitHub Actions Dashboard</Typography>
            <StyleToggle />
          </Stack>
          <Input placeholder="Search" onChange={updateFilter} sx={{my: 4}}></Input>
          <Typography level="h2" mb={2}>Active Jobs</Typography>
          <JobList status="active" filter={filter}></JobList>
          <Typography level="h2" mt={8} mb={2}>Completed Jobs</Typography>
          <JobList status="completed" filter={filter}></JobList>
        </Stack>
      </Sheet>
    </CssVarsProvider>
  )
}

export default App;
