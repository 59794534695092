import { useColorScheme } from '@mui/joy/styles';
import Button from '@mui/joy/Button';
import DarkMode from '@mui/icons-material/DarkMode'
import LightMode from '@mui/icons-material/LightMode'
import { Switch } from '@mui/joy';

export const StyleToggle = () => {
    const { mode, setMode } = useColorScheme()
    return (
        <Switch
            color={mode === "dark" ? "primary" : "neutral"}
            slotProps={{ input: { "aria-label": "dark mode" } }}
            startDecorator={<LightMode/>}
            endDecorator={<DarkMode/>}
            checked={mode === "dark"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>)=> {
                setMode(event.target.checked ? "dark" : "light")
            }}
        />
    )
}