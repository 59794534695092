import React, { useEffect, useState } from "react";

import { JobProps } from "../job/Job";
import { Alert, Box, CircularProgress, LinearProgress, Link, Skeleton, Stack, Table, Typography } from "@mui/joy";

import "./joblist.css"
import { Status, getJobs } from "../../services/api";

export interface JobListProps {
    status: Status;
    filter: string;
}

export const JobList = (props: JobListProps) => {
    const [jobs, setJobs] = useState(Array<JobProps>());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getJobs<Array<JobProps>>(props.status).then((jobs) => {
            updateJobs(jobs)
        }).catch((err) => {
            console.log(err)
        })
        const intervalId = setInterval(async () => {
            try {
                const jobs: Array<JobProps> = await getJobs(props.status);
                updateJobs(jobs)
            } catch (err) {
                console.log(err)
                updateJobs([])
            }
            
            
        }, 5000);
        return () => clearInterval(intervalId);
    }, [props.status]);

    const updateJobs = (jobs: Array<JobProps>) =>{
        if (jobs) {
            setJobs(jobs);
        } else {
            setJobs([])
        }
        setLoading(false)
    }

    const showNoJobs = () => {
        return (
            <Alert variant="solid" color="warning">No jobs found</Alert>
        )
    }

    const showTable = (jobs: Array<JobProps>) => {
        return (
            <Table variant="soft">
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>ID</th>
                        <th>Repository</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Started At</th>
                        <th>Completed At</th>
                    </tr>
                </thead>
                <tbody>
                    {jobs.map((j) => {
                        if (j.repository.toLocaleLowerCase().indexOf(props.filter.toLocaleLowerCase()) === -1) {
                            return null;
                        }
                        let icon = <CircularProgress color='primary' />;
                        switch (j.status.toLocaleLowerCase()) {
                            case "action_required":
                                icon = <CircularProgress color='warning' />;
                                break;
                            case "neutral":
                            case "requested":
                            case "waiting":
                            case "pending":
                            case "queued":
                                icon = <CircularProgress color='neutral' />;
                                break;
                            case "completed":
                                if (j.conclusion.toLocaleLowerCase() === "success") {
                                    icon = <img className="status-icon" src="/success.png" alt={j.status.toLocaleLowerCase()}></img>
                                } else {
                                    icon = <img className="status-icon" src="/failure.png" alt={j.status.toLocaleLowerCase()}></img>
                                }
                                break
                        }
                        return (
                            <tr key={j.id}>
                                <td>{icon}</td>
                                <td><Link href={j.url} target={j.id}>{j.id}</Link></td>
                                <td><Link href={j.repository_url} target={j.id}>{j.repository}</Link></td>
                                <td>{j.name}</td>
                                <td>{j.status}</td>
                                <td>{j.created_at}</td>
                                <td>{j.updated_at}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }

    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%"
        }}>
            <Stack sx={{ width: "100%", mx: 5}}>
                { loading ? <LinearProgress color='primary'/> : 
                jobs.length > 0 ? showTable(jobs) : showNoJobs()}
            </Stack>
        </Box>
    )
}