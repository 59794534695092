export type Status = "active" | "all" | "completed";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/"
    : "https://gha-dashboard.imdevinc.com/";

export const getJobs = async <T>(status: Status): Promise<T> => {
  const response = await fetch(baseUrl + status);
  return await response.json();
};
